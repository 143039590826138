import CountryCurrencyData from './currencyData.json';
import parse from 'html-react-parser';

const test_mode = true
// check width & height return it with unit
export function returnWidthHeight(data, type){
    let regex =  /^[0-9,.-]*$/
    if(data[type] !== undefined && data[type] !== null && data[type] !== ''){
        let dimension = data[type] && data[type] !== '' ? regex.test(data[type]) ? `${data[type]}${data.unit && data.unit !== "" ? data.unit : ''}` : data[type] : ''
        // let height = data.height && data.height !== '' ? regex.test(data.height) ? `${data.height}${data.unit}` : data.height : ''
        return dimension
    } else{
        return ''
    }
}

// sort call duration for call
export function returnCallDuration(call_duration,source){
    let time = call_duration && call_duration !== "" ? parseInt(call_duration) : ''
    if(time !== ""){
        if(time < 60){
            time = `${time} secs`
        } else if(time < 3600){
            time = `${Math.floor(time / 60)} mins ${Math.floor(time % 60)} secs`
        }
    }
    return source === 'order-recording-list' || source === 'admin-conversation-list' ? time :  `(Duration: ${time})`
}

// Custom comparison function for sorting by countryName
function compareCountryNames(a, b) {
  const countryNameA = a.countryName.toUpperCase();
  const countryNameB = b.countryName.toUpperCase();

  if (countryNameA < countryNameB) {
    return -1;
  }
  if (countryNameA > countryNameB) {
    return 1;
  }
  return 0;
}

// Create a new array where objects with the same currencyCode are combined
export function currencyCountryData() {
  // Sort the CountryCurrencyData array by countryName
  const sortedData = CountryCurrencyData.sort(compareCountryNames);

  return sortedData.reduce((acc, obj) => {
    const existingObj = acc.find(item => item.currencyCode === obj.currencyCode);
    if (existingObj) {
      existingObj.population += Number(obj.population);
      existingObj.countryName += `, ${obj.countryName}`;
    } else {
      acc.push(obj);
    }
    return acc;
  }, []);
}

export function getCityName (dataArray, value) {
  var search_state_id = value;
  var countryName = dataArray.filter(d => d.state_id === search_state_id);

  if (countryName.length > 0) {
    return countryName[0].state_name;
  } else {
    return "";
  }
}

export function getCountryName (dataArray, value) {
  var search_state_id = value;
  var countryName = dataArray.filter(d => d.state_id === search_state_id);

  if (countryName.length > 0) {
    return countryName[0].country_name;
  } else {
    return "";
  }
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}  

export default function getQueryParamValue(paramName) {
  const queryString = window.location.search.replace(/\+/g, '%2B');

  const urlParams = new URLSearchParams(queryString);
  
  // Check if the query parameter exists
  if (urlParams.has(paramName)) {
    // Get the value of the query parameter
    return urlParams.get(paramName);
  } else {
    // Parameter does not exist
    return null;
  }
}

export function isValidValue(value) {
  return value !== null && value !== undefined && value !== '';
}

//  It will be used for checking the user 
export function checkPageAccess(email) {
  const Email = [
    "aditya@bookanartist.co",
    "yatin@bookanartist.co",
    "gaurav@bookanartist.co",
    "jitesh@bookanartist.co",
    "javed@bookanartist.co",
    "jigar@bookanartist.co",
    "aman@bookanartist.co",
    "londoniartist@gmail.com",
    "ilham@bookanartist.co",
    "jay@bookanartist.co",
    "ankush@bookanartist.co",
  ];
  // todo: remove ayesha email
  // {Email.includes(loginUserResponse?.email) ? 

  return Email.includes(email) 
}

export function parseArtURL(value) {
  // console.log("##### parseArtURL:------", value)
  try {
    if(value !== "" && value !== null && value !== undefined && typeof(value) === "string"){
      let returnTxt = parse(unescape(
        value?.toLowerCase()?.replace(/[#\/\\,+()$~%.'":*?<>{}`!+|;@^=;\[\]]/g, '-')
          .replace(/\/|\s|&amp;/g, (match) => {
            if (match === '/') return '-';
            else if (match === '&amp;') return '&';
            else return '-';
          })
          .replace(/[^\w&\-]/g, '') // Replace all non-word characters except for "-", "&", and "_" with an empty string
          .replace(/-+/g, '-') // Replace multiple dashes with a single dash
          .replace(/-+$/, '') // Remove trailing dashes at the end
      ));

      // Check if returnTxt is null, empty, or just a single dash
      if (!returnTxt || returnTxt === '-' || returnTxt.length === 0 || (typeof returnTxt !== 'string') || returnTxt?.trim() === '') {
        returnTxt = 'art';
      }
      
      return returnTxt;
      } else {
      return "art";
    }
  } catch (error) {
    return "art"; // Return "art" in case of error
  }
}


export function getRandomLightColor() {
  // Generate random values for R, G, B in the range of 127 to 255
  // to ensure the color is light.
  const r = Math.floor(Math.random() * 128) + 127;
  const g = Math.floor(Math.random() * 128) + 127;
  const b = Math.floor(Math.random() * 128) + 127;
  return `rgb(${r}, ${g}, ${b})`;
}

export function getRandomDarkColor() {
  // Generate random values for R, G, B in the range of 0 to 128
  // to ensure the color is dark.
  const r = Math.floor(Math.random() * 128) + 64;
  const g = Math.floor(Math.random() * 128) + 64;
  const b = Math.floor(Math.random() * 128) + 64;
  return `rgb(${r}, ${g}, ${b})`;
}

export function getTagColor(tag){
  let color = ''
  if(tag === 'High'){
    color = '#FF3031'
  } else if(tag === 'Medium'){
    color = '#F4C724'
  } else{
    color = '#67E6DC'
  }
  return color
}

export function getStatusColor(status){
  let color = ''
  if(status === 'Open'){
    color = '#25CCF7'
  } else if(status === 'Closed'){
    color = '#53E0BC'
  } else if(status === 'Work In Progress'){
    color = '#F4C724'
  } else if(status === 'Needs Attention'){
    color = '#FF3031'
  } else{
    color = '#758AA2'
  }
  return color
}
export function checkModuleAccess(login_user_data) {
  
  let access = true
  if(login_user_data?.role_id == '2' && login_user_data?.full_access == '1'){
  access = false
  }
  return access
  
  
}

export function checkSellerStatusAccess(email) {
  const Email = [
    "yatin@bookanartist.co",
    "gaurav@bookanartist.co",
    "jay@bookanartist.co",
    "illham@bookanartist.co",
    "aditya@bookanartist.co",
    "jitesh@bookanartist.co",
    "javed@bookanartist.co",
    "jigar@bookanartist.co",
    "aman@bookanartist.co",
    "londoniartist@gmail.com",
    "ankush@bookanartist.co",
  ];
  // todo: remove ayesha email
  // {Email.includes(loginUserResponse?.email) ? 

  return Email.includes(email) 
}

export function checkConversationMessageDeleteAccess(email) {
  const Email =test_mode== false ? [
    "yatin@bookanartist.co",
    "gaurav@bookanartist.co",
    "jay@bookanartist.co",
    "aditya@bookanartist.co",
    "jitesh@bookanartist.co",
    "jigar@bookanartist.co",
    "aman@bookanartist.co",
  ] :  [
    "yatin@bookanartist.co",
    "gaurav@bookanartist.co",
    "jay@bookanartist.co",
    "aditya@bookanartist.co",
    "londoniartist@gmail.com",
    "jitesh@bookanartist.co",
    "jigar@bookanartist.co",
    "aman@bookanartist.co",
    "ankush@bookanartist.co",
  ]


  return Email.includes(email) 
}
