import { Button, Popover } from "antd";
import { useSelector } from "react-redux";
import mixPanel from "./mixPanel";

const test_mode = true;
let admin_conversation_url = "https://admin.bookanartist.co/conversations?search=";
if(test_mode === true){
  admin_conversation_url = "https://admin.hireanartist.com.au/conversations?search=";
  // admin_conversation_url = "http://localhost:3000/conversations?search=";
}

export default function ConversationLink({text}) {
  const loginUserResponse = useSelector(state => {
    if (state.Common.login_user_data && state.Common.login_user_data?.admin_id !== undefined) {
      return state.Common.login_user_data;
    }
  });
    const conversationContent = (
        <div>
          <a target="_blank" href={`${admin_conversation_url}${text}`} onClick={()=>{
            mixPanel.track('Conversation URL', {
            "admin_email": `${loginUserResponse?.email}`,
            "pathname": `${window.location.pathname}`
        })}}>
            <Button type="primary"  className="ml-5 mr-5"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 48 48"><path fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" strokeWidth="3" d="m43.51 36.19l-.55-2A21.5 21.5 0 1 0 34.14 43l2 .55l7.2 1.93a1.67 1.67 0 0 0 2-1.18a1.61 1.61 0 0 0 0-.87Zm-10-14.39a2.2 2.2 0 1 1-2.2 2.2a2.23 2.23 0 0 1 2.22-2.2Zm-19.04 4.4a2.2 2.2 0 1 1 2.2-2.2a2.23 2.23 0 0 1-2.2 2.2m9.53 0a2.2 2.2 0 1 1 2.2-2.2a2.23 2.23 0 0 1-2.2 2.2"/></svg>&nbsp;Conversation </Button>
          </a>
        </div>
      );
  return (
    <Popover content={conversationContent} title="Conversation Window">
    <a target="_blank" href={`${admin_conversation_url}${text}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 48 48"><path fill="none" stroke="blue" stroke-linecap="round" stroke-linejoin="round" strokeWidth="3" d="m43.51 36.19l-.55-2A21.5 21.5 0 1 0 34.14 43l2 .55l7.2 1.93a1.67 1.67 0 0 0 2-1.18a1.61 1.61 0 0 0 0-.87Zm-10-14.39a2.2 2.2 0 1 1-2.2 2.2a2.23 2.23 0 0 1 2.22-2.2Zm-19.04 4.4a2.2 2.2 0 1 1 2.2-2.2a2.23 2.23 0 0 1-2.2 2.2m9.53 0a2.2 2.2 0 1 1 2.2-2.2a2.23 2.23 0 0 1-2.2 2.2"/></svg>
      </a> 
  </Popover>
  
  )
}
